.titles_wrap{
    padding:50px;
    background-color:#09182c;
    position:relative
}

.titles_wrap h2{
    margin:0px;
    color:#fff;
    text-transform: capitalize;
}

.titles_wrap img{
    position:absolute;
    top:0px;
    right:0px;
    height:100px;
}

@media only screen and (max-width:900px){
    .titles_wrap img{
        height:50px;
    }
}