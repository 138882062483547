.landing {
  min-height: 700px;
  width: 100%;
  position: relative;
  overflow: hidden;
  /*height: calc(100vh - 85px);*/
}

.landing video.landingv {
  position: absolute;
  /*top: 50%;
  left:50%;
  transform: translate(-50%,-50%);*/
  width: 100%;
  object-fit: cover;
  background-position: center;
  z-index: 0;
  height: calc(100vh - 85px);
}

.landing video.mobile_video {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  z-index: 0;
  display: none;
}

.landing .content_sec {
  position: relative;
  z-index: 1;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.landing .content_sec .text_side {
  padding-top: 150px;
}

.landing .content_sec .text_side p.para {
  margin-top: 20px;
}


.flex-container-landing .btn_wrap,
.landing .content_sec .text_side .btn_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  gap: 30px;
}

.btn_wrap a.first,
/*.landing .content_sec .text_side*/ .btn_wrap button {
  padding: 16px 32px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
  border-style: none;
  transition: all 0.3s ease-in-out;
  width: 250px;
}

.flex-container-landing .btn_wrap button {
  width: 200px;
  padding: 16px 8px;
}

a.first,
/*.landing .content_sec .text_side .btn_wrap*/ button.first {
  background-color: #09182c;
  color: #fff;
  border: 2px solid #09182c;
}

.flex-container-landing button.first {
  background-color: #7829f9;
  border: 2px solid #7829f9;
}

a.first:hover {
  background-color: #7829f9;
}
/*.landing .content_sec .text_side .btn_wrap*/ button.first:hover {
  background-color: #7829f9;
  border: 2px solid #7829f9;
}

.flex-container-landing button.first:hover {
  color: #7829f9;
  background-color:transparent;
  border: 2px solid #7829f9;
}

a.first.active,
button.first.active {
  background-color: #7829f9;
  border: 2px solid #7829f9;
}

.landing .content_sec .text_side .btn_wrap button.second {
  background-color: transparent;
  color: #000;
  border: 2px solid #09182c;
}

.flex-container-landing .btn_wrap button.second {
  background-color: transparent;
  color: #09182c;
  border: 2px solid #09182c;
}

.landing .content_sec .text_side .btn_wrap button.second:hover {
  background-color: #09182c;
  color: #fff;
}

.flex-container-landing  .btn_wrap button.second:hover {
  background-color: #09182c;
  border: 2px solid #09182c;
  color: #fff;
}
/*.landing .content_sec .text_side .btn_wrap button:hover svg {
  display: none;
  margin-bottom: -20px;
}*/

.landing .content_sec .text_side .btn_wrap button svg {
  margin-bottom: -2px;
  margin-left: 45px;
}

.flex-container-landing .btn_wrap button svg {
  margin-bottom: -2px;
  margin-left: 20px;
}

/*.landing .content_sec .text_side .btn_wrap button svg.hover {
  display: none;
  float: right;
  margin-bottom: -20px;
}*/

.flex-container-landing .btn_wrap button:hover svg.hover
.landing .content_sec .text_side .btn_wrap button:hover svg.hover {
  display: block;
  margin-bottom: -20px;
}


.landing .content_sec .small_video_wrap {
  display: none;
  margin-top:40px;
}
.landing .content_sec .small_video_wrap video{
  height:100%;
  width:100%;
}

.flex-container-landing {
  display: flex;
}

.flex-landing-first-block {
  width: 45vw;
  background: #fff; /*linear-gradient(, 90%, #fff);;*/
  padding: 3rem;
}

.flex-landing-first-block p {
  color: #556072;
}

.flex-landing-first-block h1 {
  color: #09182c;
  margin-bottom: 2rem;
  font-size: 5vw;
  line-height: 4.2vw;
}

.flex-landing-second-block {
  width: 55vw;
  position: relative;
}

.flex-landing-second-block video {
  width: 55vw;
}

.blur-separator {
  z-index: 6;
  width: 20%;
  height: 100%;
  /*-webkit-backdrop-filter: blur(24px);
   backdrop-filter: blur(24px); */
  background-image: linear-gradient(90deg,#081831 64%,rgba(0,0,0,0));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: -10%;
  right: auto;
}


.flex-container-landing {
  display: none;
}

.landing-top-excerpt {
  display: none;
}

@media only screen and (max-width: 1240px) {
  .flex-container-landing .btn_wrap button svg {
    display: none;
  }

}

@media only screen and (max-width: 1000px){
  .landing .content_sec .text_side{
    padding-top:100px;
  }
}

@media only screen and (max-width: 400px){
  .landing video.landingv{
    display: none;
  }
  .landing .content_sec{
    grid-template-columns: 1fr;
  }
  .landing .content_sec .text_side{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing .content_sec .text_side p.para{
    text-align: center;
  }
  .landing video.mobile_video{
    display: block;
  }
  .landing .content_sec .text_side{
    padding-top:80px;
  }

  .landing .content_sec .text_side h1 {
    text-align: center;
  }
}


@media only screen and (max-width: 560px) {
  .landing .content_sec .text_side .btn_wrap {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
  .landing .content_sec .text_side .btn_wrap a {
    width: 100%;

  }
  .landing .content_sec .text_side .btn_wrap a button{
    width: 100%;
    
  }
}


@media only screen and (max-width: 1040px) {

  .desktop-home-landing {
    display: none;
  }

  .flex-container-landing {
    display: flex;
  }

  .flex-container-landing .btn_wrap {
    display: block;
  }

  .flex-container-landing .btn_wrap > button:first-child {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 750px){
.flex-landing-first-block {
  padding: 2rem 1.5rem;
}
}
@media only screen and (max-width: 600px){

  .flex-container-landing .btn_wrap > button:first-child {
    margin-bottom: 0;
  }

  .flex-container-landing .btn_wrap {
    display: flex;
  }

  .flex-container-landing {
    display: block;
  }
  
  .flex-landing-first-block {
    width: 100%;
  }
  
  .flex-landing-second-block {
    width: 100%;
    position: relative;
  }
  
  .flex-landing-second-block video {
    width: 100%;
  }
  
  .blur-separator {
    z-index: 6;
    width: 100%;
    height: 20%;
    /*-webkit-backdrop-filter: blur(24px);
     backdrop-filter: blur(24px); */
    background-image: linear-gradient(180deg,#081831 64%,rgba(7,0,17,0));
    position: absolute;
    top: -10%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .landing-top-excerpt {
    display: block;
  }
}

@media only screen and (min-width: 1560px) {
  .flex-landing-first-block h1 {
    font-size: 64px;
    line-height: 72px;
  }
}
