.blog_detail{

}

.blog_detail .bi_flex{
    display: flex;
    gap:95px;
    margin-top:100px;
    padding-bottom:100px;
}

.blog_detail .bi_flex .card_wrap{
    width:300px;   
    position:relative;
}

.blog_detail .bi_flex .card_wrap .card{
    width:300px;
    background-color:#09182c;
    padding:0px 24px 24px;
    position:sticky;
    top:90px;
}

.blog_detail .bi_flex .card_wrap .card button.redirect{
    padding:24px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:15px;
    border-style: none;
    background-color:#09182c;
    border-bottom:1px solid #ffffff39;
    width:100%;
}

.blog_detail .bi_flex .card_wrap .card button.redirect p{
    color:#fff;
    text-transform: capitalize;
    font-size:16px;
    line-height: 24px;
    font-weight:600;

}
.blog_detail .bi_flex .card_wrap .card .text{
    padding:24px;
}
.blog_detail .bi_flex .card_wrap .card h3{
    color:#fff;
    margin:30px 0px;
}

.blog_detail .bi_flex .card_wrap .card input{
    height:55px;
    width:100%;
    padding:16px 24px;
    font-size:16px;
    line-height: 24px;
    font-weight: 500;
    color:#09182c;
}
.blog_detail .bi_flex .card_wrap .card input:focus{
outline: none;
}
.blog_detail .bi_flex .card_wrap .card input::placeholder{
    color:#8a8a8a;   
}
.blog_detail .bi_flex .card_wrap .card button.subscribe{
    height:55px;
    width:100%;
    padding:16px 24px;
    background-color:#02f3a2;
    color:#09182c;
    font-size:16px;
    line-height:24px;
    font-weight:600;
    text-transform: capitalize;
    border-style: none;
    margin-top:15px;
    transition:all 0.3s ease-in-out;

}
.blog_detail .bi_flex .card_wrap .card button.subscribe:hover{
    background-color:#7829f9;
    color:#fff;
}

.blog_detail .bi_flex .card_wrap .card .sharebox{
margin-top:40px;
width:100%;
border-top:1px solid #ffffff39;
padding-top:40px;
display: flex;
justify-content: space-between;
align-items: center;
}
.blog_detail .bi_flex .card_wrap .card .sharebox p.share{
    color:#F1F3F9;
    font-weight:16px;
    line-height: 24px;
    font-weight:500;
}

.blog_detail .bi_flex .card_wrap .card .sharebox .icons{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}

.blog_detail .bi_flex .block_wrap{
    width:calc(100% - 395px);
}

.blog_detail h2.post_title{
    margin:100px 0px;
    text-align: center;
}

.blog_detail .post_grid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:25px;
    width:100%;
    padding-bottom:60px;
}



.blog_detail .post_grid .blog{
    padding:40px 40px 80px;
    background-color:#f5f6fc;
    transition:all 0.3s ease-in-out;
    position:relative;
    overflow: hidden;
}

.blog_detail .post_grid .blog h3{
    margin:20px 0px;
    transition: all 0.3s ease-in-out;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.blog_detail .post_grid .blog .date_line{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blog_detail .post_grid .blog .img_wrap{
    width:100%;
    margin-top:50px;
}


.blog_detail .post_grid .blog .img_wrap img{
    width:100%;
}


.blog_detail .post_grid .blog .date_line button{
    padding:8px 12px;
    font-size:12px;
    list-style:12px;
    text-transform: capitalize;
    background-color:#7829f9;
    color:#ffffff;
    border-style: none;
    margin-right:10px;
    font-weight:700;
    letter-spacing:1.5px;
    transition:all 0.3s ease-in-out;
}

.blog_detail .post_grid .blog .date_line p.date{
    font-size:15px;
    font-weight:700;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    transition:all 0.3s ease-in-out;
}
.blog_detail .post_grid .blog:hover .overlay{
    top:0%;
    padding:40px;
}
.blog_detail .post_grid .blog .overlay{
    background-color:#09182c;
}

.blog_detail .post_grid .blog:hover .overlay button{
    background-color:#02f3a2;
    color:#09182c;
}
.blog_detail .post_grid .blog:hover .overlay p.date{
    color:#fff;
}

.blog_detail .post_grid .blog:hover .overlay h3{
    color:#fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  
}

.blog_detail .post_grid .blog:hover .overlay .learn{
    opacity: 1;
    position:absolute;
    left:40px;
    bottom:30px;
}

.blog_detail .post_grid .blog .overlay{
    position:absolute;
    height:100%;
    width:100%;
    top:100%;
    left:0px;
    transition:all 0.3s ease-in-out; 
    padding:40px;
}


.blog_detail .wp-block-buttons {
    margin: 50px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_detail .wp-block-buttons a {
    padding: 16px;
    border: 2px solid #7829f9;
    color: #7829f9;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    width: 200px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
}

.blog_detail .wp-block-buttons a:hover {
    background-color: #7829f9;
    color: #fff;
}

#disqus_thread {
    margin-bottom: 40px;
}

.allcontent{
    margin:100px 0px;
}

.allcontent .content_grid {
    margin-bottom: 100px;
}

.allcontent h2{
text-align: center;
text-transform: capitalize;
margin:100px 0px;
}

.allcontent .tab_wrap{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    gap:12px;
}

.allcontent .tab_wrap button{
    padding:12px 36px;
    background-color:#fff;    
    font-size:16px;
    line-height:24px;
    font-weight:600;
    transition:all 0.3s ease-in-out;
    border:2px solid #fff;
}

.allcontent .tab_wrap button:hover{
    border:2px solid #7829f9;
}

.allcontent .tab_wrap button.active{
    border:2px solid #7829f9;
}

.allcontent .content_grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
    margin-top:60px;
}

.allcontent .dropdown_wrap{
    padding:15px 0px;
    background-color:#fff;
    position:relative;
    display: none;
    height:40px; 
}

.allcontent .dropdown_wrap img{
    width:20px;
    position:absolute;
    right:20px;
    top:50%;
    transform:translateY(-50%);
    transform-origin: center;
    transition:all 0.3s ease-in-out;
}

.allcontent .dropdown_wrap img.open{
    transform:translateY(-50%) rotate(-180deg);
}   

.allcontent .dropdown_wrap p{
    color:#09182c;
    font-size:16px;
    line-height: 20px;
    font-weight:700;
    padding:4px 20px;
}

.allcontent .dropdown_wrap .option_list{
    width:100%;
    background-color:#fff;
    position:absolute;
    top:45px;
    transition: all 0.3s ease-in-out;
    transform-origin: top center;
    z-index:2
}

.allcontent .dropdown_wrap .option_list.close{
    transform: scaleY(0);
}

.allcontent .dropdown_wrap .option_list.open{
    transform: scaleY(1);
}

.allcontent .dropdown_wrap .option_list button{
    font-size:16px;
    line-height: 20px;
    font-weight:700;
    padding:12px 20px;
    width:100%;
    text-align: left;
    border-style: none;
    color:#09182c;
    background-color:#fff;
    text-transform: capitalize;
    transition:all 0.3s ease-in-out;
}


.allcontent .dropdown_wrap .option_list button:hover{
    background-color:#7829f9;
    color:#fff;
}
.allcontent .dropdown_wrap .option_list button.active{
    background-color:#7829f9;
    color:#fff;
}

@media only screen and (max-width:1100px){
    .blog_detail .post_grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width:1000px){
    .blog_detail .bi_flex{
        gap:25px;
        margin-top:60px;
        padding-bottom:60px;
    }
    
}

@media only screen and (max-width:700px){
    .blog_detail .bi_flex{
        gap:40px;
        margin-top:60px;
        padding-bottom:60px;
        flex-direction: column-reverse;
    }
    .blog_detail .bi_flex .card_wrap .card{
        width:100%;
    }
    .blog_detail .bi_flex .card_wrap {
        width:100%;
    }
    
}

@media only screen and (max-width:600px){
    .blog_detail .post_grid{
        grid-template-columns: 1fr;
    }
    .blog_detail h2.post_title{
        margin:60px 0px;
        text-align: center;
    }
}

@media only screen and (max-width:1150px){
    .allcontent .tab_wrap button{
        padding:12px 20px;
    }
    .allcontent .content_grid{
        grid-template-columns:1fr 1fr;
        gap:20px;
        margin-top:60px;
    }
}



@media only screen and (max-width:950px){
    .allcontent .tab_wrap{
        display: none;
    }
    .allcontent .dropdown_wrap{
       display: flex;
       justify-content: space-between;
       align-items: center;
    }
}

@media only screen and (max-width:600px){
    .allcontent .content_grid{
        grid-template-columns:1fr;
    }
    .allcontent h2{
        margin:60px 0px;
    }
}