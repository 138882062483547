.partners_info{
    padding:100px 0px 0px;
}

.partners_info h2{
    text-align: center;
}

.partners_info p{
    margin-top:20px;
    text-align: center;
}

.partners_info .img_alides{
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   gap:25px;
margin-top:40px;
}

.partners_info .img_alides img{
    border:4px solid #7829F9;
    padding:20px;
    max-width:350px;
}

@media only screen and (max-width:900px){
    .partners_info p{
        padding-right:0px;
    }
}

@media only screen and (max-width:600px){
    .partners_info{
        padding:60px 0px 0px;
    }
    .partners_info h2{
        text-align: center;
       }
    .partners_info p{
     text-align: center;
    }
    .partners_info .img_alides img{
        width:200px;
    }
}

@media only screen and (max-width:450px){
    .partners_info .img_alides img{
        width:100%;
    }
}