.permitted {
  padding-bottom: 60px;
}

.permitted h2 {
  text-align: center;
  margin-bottom: 50px;
}

.permitted p {
  margin: 20px 0px 30px;
}

.permitted a {
  color: #7829f9;
}
