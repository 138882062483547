.compare {
  background-color: #fff;
  padding-bottom: 100px;
}
.compare .content_sec.border {
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
}
.compare .titles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 40px;
  padding: 40px 0px 10px;
  overflow: auto;
}

.compare .titles p {
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 120px;
}

.compare .titles p span {
  font-size: 12px;
}

.compare .sec_title {
  padding: 20px 15px;
  background-color: #d3d3d3;
  position: relative;
}

.compare .sec_title.close:after {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  top: 14px;
  right: 15px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
  display: none;
}

.compare .sec_title.open:after {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  top: 20px;
  right: 15px;
  transform: rotate(135deg);
  transition: all 0.3s ease-in-out;
  display: none;
}

.compare .sec_title p {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.compare .data_wrap {
  overflow: auto;
  transition: all 0.3s ease-in-out;
}

.compare .data_wrap .data_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 20px;
}

.compare .data_wrap .data_grid p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  min-width: 100px;
}
.compare .data_wrap .data_grid p.center {
  text-align: center;
  min-width: 60px;
}

@media only screen and (max-width: 800px) {
  .compare .data_wrap .data_grid p {
    font-size: 14px;
  }
  .compare .titles p {
    font-size: 14px;
  }
  .compare .titles p.empty {
    display: none;
  }
  .compare .sec_title {
    padding: 10px 5px;
    background-color: #d3d3d3;
  }
}

@media only screen and (max-width: 1100px) {
  .compare .titles {
    gap: 10px;
  }
  .compare .data_wrap .data_grid {
    padding: 15px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .compare .titles p.empty {
    display: none;
  }

  .data_wrap.close {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .data_wrap.open {
    height: auto;
    transition: all 0.3s ease-in-out;
  }
  .sec_title {
    margin: 5px 0px;
  }
  .compare .sec_title.close:after {
    display: block;
  }

  .compare .sec_title.open:after {
    display: block;
  }
  .compare  h2{
    text-align: center;
  }
  .compare {
    padding-bottom:60px;
  }
}
