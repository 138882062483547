.faq{
    padding-bottom:60px;
    }
    
    .faq h2{
    text-align: center;
    margin-bottom:50px;
    }
    
    .faq p{
        margin:20px 0px 30px;
    }
