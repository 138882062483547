.popup_overlay {
  min-height: 100vh;
  width: 100%;
  background-color: #7829f9;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;
  padding: 250px 0px 0px 250px;
}

.popup_overlay button {
  height: 48px;
  width: 48px;
  border-style: none;
  background-color: transparent;
  position: absolute;
  top: 40px;
  left: 40px;
  position: fixed;
  top: 40px;
  left: 40px;
}

.popup_overlay .info_wrap {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  padding: 73px 100px 100px;
  box-shadow: -8px -2px 20px 0px rgba(0, 0, 0, 0.25);
}

.popup_overlay .info_wrap .top {
  height: 650px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.popup_overlay .info_wrap .top .img_wrap {
  position: relative;
}

.popup_overlay .info_wrap .top .img_wrap img.profile {
  border: 4px solid #7829f9cc;
  position: relative;
  z-index: 1;
}

.popup_overlay .info_wrap .top .img_wrap img.bg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translate(-40%, 40%);
  z-index: 0;
}

.popup_overlay .info_wrap .trigrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.popup_overlay .info_wrap .top h2 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000;
}

.popup_overlay .info_wrap .top p {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #556072;
}

.popup_overlay .info_wrap .top .trigrid {
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.popup_overlay .info_wrap .top .trigrid .info_card .dot {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(-50%, -50%);
}
.popup_overlay .info_wrap .trigrid .info_card {
  padding: 25px 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px dashed #c1c1c1;
  position: relative;
}
.popup_overlay .info_wrap .trigrid .info_card.no_top {
  border-top: none;
}

.popup_overlay .info_wrap .trigrid .info_card.left {
  border-left: 1px dashed #c1c1c1;
}

.popup_overlay .info_wrap .trigrid .info_card.right {
  border-right: 1px dashed #c1c1c1;
}
.popup_overlay .info_wrap .trigrid .info_card .icon_wrap {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_overlay .info_wrap .trigrid .info_card p.year {
  font-size: 10px;
  font-weight: 400;
  color: #8c8c8c;
  text-align: center;
  margin: 4px 0px;
}

.popup_overlay .info_wrap .trigrid .info_card p.title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.popup_overlay .info_wrap .trigrid .info_card p.desc {
  font-size: 16px;
  font-weight: 600;
  color: #556072;
  text-align: center;
  margin-top: 13px;
}
.popup_overlay .info_wrap .trigrid .info_card .dot {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #7829f9;
}

/* tab view */

.popup_overlay .tab_view {
  padding: 0px;
  width: 100%;
  display: none;
  position:relative;
}
.popup_overlay .tab_view .closer{
position:fixed;
top:15px;
left:15px;
height:25px;
width:25px;
background-color:#fff;
z-index:999;
}
.popup_overlay .tab_view .closer .a{
  position:absolute;
  width:20px;
  height:2px;
  background-color:#7829f9;
  transform: rotate(-45deg);
  margin-top:9px;
  margin-left:2px;
}

.popup_overlay .tab_view .closer .b{
  position:absolute;
  width:20px;
  height:2px;
  background-color:#7829f9;
  transform: rotate(45deg);
  margin-top:9px;
  margin-left:2px;
}

.popup_overlay .tab_view .profile img {
  max-width: 300px;
  width: 100%;
  border: 4px solid #7829f9cc;
}

.popup_overlay .tab_view h3 {
  margin-top: 6px;
  text-align: center;
}

.popup_overlay .tab_view p {
  text-align: center;
}

.popup_overlay .tab_view .profile {
  padding: 100px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
 
}

.popup_overlay .tab_view .profile img.grid{
  position:absolute;
  right:0px;
  bottom:10px;
  border-style: none;
  z-index: 0;
}
.popup_overlay .tab_view .profile img.profilepic{
  z-index: 1;
  position:relative;
}
.popup_overlay .tab_view .time_line {
  padding: 50px 0px 0px;
  width: 100%;
 
}

.popup_overlay .tab_view .time_line .card {
  width: 100%;
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
  overflow: hidden;
}

.popup_overlay .tab_view .time_line .card img.bg{
  display: none;
  position:absolute;
  bottom:0px;
  height:300px;
}

.popup_overlay .tab_view .time_line .card img.right{
  display: block;
  position: absolute;
  right:0px;
  bottom:0px;
}
.popup_overlay .tab_view .time_line .card img.left{
  display: block;
  position: absolute;
  left:0px;
  bottom:0px;  
}

.popup_overlay .tab_view .time_line .card img.right_grid{
  display: block;
  position: absolute;
  right:0px;
  bottom:0px;
  transform:translate(50%,50%);
}
.popup_overlay .tab_view .time_line .card img.left_grid{
  display: block;
  position: absolute;
  left:0px;
  bottom:0px;  
  transform:translate(-50%,50%);
}

.popup_overlay .tab_view .time_line .card p.year {
  font-size: 11px;
  line-height: 14px;
  color: #bbbbbb;
  margin-top:7px;
}

.popup_overlay .tab_view .time_line .card p.title {
  font-size: 14px;
  line-height: 18px;
  font-weight:700;
  color: #000000;
  margin-top: 7px;
}

.popup_overlay .tab_view .time_line .card p.para {
  font-size: 14px;
  line-height: 18px;
  color: #556072;
  margin-top: 7px;
}

.popup_overlay .tab_view .time_line .card .line{
    height:45px;
    width:0px;
    border-left:1px dotted rgb(116, 116, 116);
    margin-top:15px;
    position:relative;
}

.popup_overlay .tab_view .time_line .card .line .dot{
    height:6px;
    width:6px;
    border-radius: 50%;
    background-color: #7829f9;
    position:absolute;
    bottom:0px;
    left:0px;
    transform: translateX(-50%);
}

.tab_view .bottom_img{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width:480px){
    .popup_overlay .tab_view .time_line .card{
        padding:20px 35px;  
    }
    .popup_overlay .tab_view .profile {
        padding: 100px 15px 0px;
    }
}


@media only screen and (max-width: 1150px) {
    .popup_overlay {
      padding: 100px 0px 0px 100px;
    }
    .popup_overlay .info_wrap {
      padding: 50px;
    }
    .popup_overlay .info_wrap .top h2 {
      font-size: 32px;
      line-height: 36px;
    }
    .popup_overlay .info_wrap .top p {
      font-size: 18px;
      line-height: 22px;
    }
    .popup_overlay .info_wrap .top .img_wrap img {
      height: 300px;
    }
  }
  
  @media only screen and (max-width: 750px){
    .popup_overlay {
      background-color: #fff;
      padding: 0px;
    }
    .popup_overlay .info_wrap {
      display: none;
    }
    .popup_overlay .tab_view{
      display: block;
    }
  }
  
  @media only screen and (max-width: 600px){
    .popup_overlay .tab_view .profile img.bg{
      width:100px;
    }
  }