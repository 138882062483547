.statistics {
  height: calc(100vh - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}

.statistics .item {
  flex: 0 0 33.333333%;
  max-width: 430px;
  width: 33.333333%;
  padding: 20px;
  position: relative;
  overflow: hidden;
  transition:all 0.4s ease-in-out;
  text-align: center;
}

.statistics .item h2 {
  margin:50px 0px;
  text-align: center;
} 

.statistics .item svg {
  width: 30%;
} 

.statistics .item .title {
  margin:20px 0px;
  text-align: center;
}  

.statistics .item p.tag {
  font-size: 28px;
  font-weight: 600;
  color: #09182c;
  text-align: center;
  margin-bottom: 20px;
}

.statistics .item-eco .title,
.statistics .item-eco .para,
.statistics .item-eco p.tag {
  color: #45e097;
}

@media screen and (max-width:750px){
  
  .statistics {
    display: block;
    height: auto;
    padding: 100px 0px;
  }

  .statistics .item {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 80px 0px;
    padding: 0 50px;
  }

  .statistics .item svg {
    width: 15%;
    min-width: 70px;
  } 
}