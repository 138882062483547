/* Font */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap");

/* Typography */
@import "./assets/commanstyle/typography.css";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior:unsset;
}

body {
  /*background-color: #f1f3f9;*/
  background-color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-user-drag: none;

}
::selection {
  background-color: #7829f9;
  color:#fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content_sec {
  max-width: 1320px;
  padding: 0px 60px;
  margin: 0px auto;
}

button:disabled {
  cursor: not-allowed;
}

.carousel-root {
  margin: 80px 0;
}

.control-dots {
  display: none;
}

.carousel .slide > div {
  margin: auto;
}

.roadmap-btn-list-container {
  margin: 40px 0;
  text-align: center;
}

.roadmap-navigation {
  display: inline-block;
  text-align: center;
}

.roadmap-navigation.btn_wrap button {
  margin:10px;
  padding: 15px;
  width: 190px;
  font-size: 14px;
}

.roadmap-ordered-list {
  list-style-type: none; /* Remove default numbering */
  counter-reset: custom-counter; /* Reset the counter */
  padding: 40px 10px 40px 30px!important;
}

.roadmap-ordered-list li {
  counter-increment: custom-counter; /* Increment the counter */
  position: relative;
  padding-left: 35px!important; /* Add padding to the left for the circle */
  margin-bottom: 30px;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
}

.roadmap-ordered-list li .roadmap-quarter {
  font-size: 9px;
  padding: 5px 10px;
  background-color: orange;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  font-weight: 900;
  min-width: 65px;
  max-height: 25px;
}

.roadmap-ordered-list li::before {
  content: counter(custom-counter); /* Display the counter value */
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background-color: #7829f9; /* Circle color */
  color: white;
  text-align: center;
  line-height: 25px;
  border-radius: 50%; /* Make it a circle */
  font-size: 14px;
}

.roadmap-unordered-list {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 0; /* Remove default padding */
  padding: 40px 10px 40px 30px!important;
}

.roadmap-unordered-list li {
  position: relative;
  text-decoration: line-through; /* Strike through completed items */
  color: #888; /* Gray out completed items */
  padding-left: 35px!important; /* Add padding to the left for the circle */
  margin-bottom: 30px;
  line-height: 25px;
}

.roadmap-unordered-list li::before {
  content: '\2713';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  color: #2ecc71;
  border: 2px solid #2ecc71; /* Add a border */
  border-radius: 50%; /* Make it a circle */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.roadmap-updates-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #dfdede;
  margin: 40px 0;
}

.text .text-block {
  width: 100%;
}

ul.roadmap-updates-list > li {
  position: relative;
  border-bottom: 1px solid #dfdede;
  padding: 15px 0;
  transition: padding-bottom .2s;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.post_grid .blog {
  cursor: pointer;
}

ul.roadmap-updates-list h5 {
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  position: relative;
  line-height: 27px;
}

ul.roadmap-updates-list h5 .date {
  font-size: 12px;
  color: #888;
  top: 0;
  right: 0;
}

ul.roadmap-updates-list h5 a {
  color: inherit;
  display: block;
  position: relative;
  padding-left: 25px;
  text-decoration: none;
  transition: color .2s;
  max-width: 37rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.roadmap-updates-list h5 .icons {
  width: 16px;
  height: 16px;
  opacity: .25;
  margin-right: 5px;
  position: absolute;
  top: 5px;
  left: 0;
}

ul.roadmap-updates-list h5 img {
  vertical-align: middle;
}

ul.roadmap-updates-list h5 .minus-circle {
  display: none;
}

ul.roadmap-updates-list .announcement-title {
  display: flex;
  justify-content: space-between;
}

ul.roadmap-updates-list .announcement-content {
  max-height: 0;
  transition: max-height .2s ease-in-out;
  overflow: hidden;
  margin-left: 25px;
  font-size: 14px;
  line-height: 1.5em;
}

ul.roadmap-updates-list .announcement-content p {
  margin: 1em 0 0;
  font-size: 14px;
}

ul.roadmap-updates-list .button_indigo-white {
  background: none;
  border: 1px solid #7829f9!important;
  color: #7829f9!important;
}

ul.roadmap-updates-list .more {
  display: inline-block;
  margin-top: 1em;
  font-size: 13px;
  padding: 0 10px;
}

ul.roadmap-updates-list .button {
  font-weight: 400;
  height: 4rem;
  line-height: 4rem;
  border-radius: .4rem;
  color: #fff;
  border: 0;
  cursor: pointer;
}

ul.roadmap-updates-list > li.open h5 .minus-circle {
  display: block;
}

ul.roadmap-updates-list > li.open h5 .plus-circle {
  display: none;
}

ul.roadmap-updates-list > li.open .announcement-content {
  max-height: 300px;
}

.bi-block-image-text .video_wrap_mobile,
.bi-block-image-text .video_wrap {
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.bi-block-image-text .video_wrap_mobile {
  padding: 0;
  margin-top: 50px;
}

.bi-block-image-text .video_wrap_mobile img,
.bi-block-image-text .video_wrap img {
  max-width: 100%;
}

.bi-block-image-text .video_wrap_mobile img {
  display: none;
}

.contact-container-bordered h2 {
  margin-bottom: 40px;
}

.verify-email {
  padding: 100px 0;
  text-align: center;    
  min-height: calc(100vh - 74px);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.loading-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.center-elements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 274px);
}

.carousel-status {
  visibility: hidden;
}

/*.carousel.carousel-slider .control-arrow {
  opacity: .4;
  background: gray!important;
}*/

.carousel .slide iframe {
  width: 100%!important;
  margin: 0 0 40px!important;
}

.carousel .thumbs-wrapper .control-arrow.control-next::before {
  border-left-color: black!important;
}

.carousel .thumbs-wrapper .control-arrow.control-prev::before {
  border-right-color: black!important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff!important;
}

.grecaptcha-badge { visibility: hidden; }


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.allcontent .btn_wrap button.btn_purple {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.allcontent .btn_wrap button.btn_purple {
  padding: 16px;
  border: 2px solid #7829f9;
  color: #7829f9;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 200px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  margin-left: auto;
  margin-right: auto;
}

.allcontent .btn_wrap button.btn_purple:hover {
  background-color: #7829f9;
  color: #fff;
}

.speak-article {
  background-color: transparent;
}

.partners-distributor-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the elements horizontally */
}

.partners-distributor-item {
  width: calc(50% - 20px); /* 50% width with some spacing (adjust as needed) */
  margin: 10px; /* Add spacing between elements */
  text-align: center; /* Center the content horizontally */
  padding: 40px 40px 80px 40px; /* Add padding for better appearance */
  box-sizing: border-box; /* Include padding in element's total width */
  border: 2px solid #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center items horizontally in each item */
  position: relative; /* Relative positioning for button */
  min-height: 380px;
}

.partners-distributor-item-content {
  flex-grow: 1; /* Grow to fill available space vertically */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure item fills available height */
}

.partners-distributor-centered-image {
  max-width: 100%;
}

.partners-distributor-button {
  position: absolute;
  bottom: 10px; /* Adjust the button's distance from the bottom */
  border: 2px solid #7829f9;
  color: #7829f9;
  padding: 10px 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.knowledgebase-page {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create 12 equal-width columns */

  /* Adjust grid gap and padding as needed
  gap: 10px; */
  padding: 20px;
}

.knowledgebase-column {
  padding: 20px;
}

.knowledgebase-index {
  grid-column: span 3; /* Occupies 3 grid units */
  background-color: #f5f6fc;
}

.knowledgebase-content {
  grid-column: span 9; /* Occupies 7 grid units */
  
}

.single_blog iframe {
  max-width: 100%!important;
}

.knowledgebase-content .single_blog article ul,
.knowledgebase-content .single_blog article ol {
  padding: 24px 0 24px 50px;
}

.knowledgebase-content .single_blog article ul li,
.knowledgebase-content .single_blog article ol li {
  margin-bottom: 10px;
}


.knowledgebase-article-type a {
  font-weight: normal!important;
}

.knowledgebase-sidebar {
  /*width: 250px;*/
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.knowledgebase-sidebar ul > li > ul {
  display: none;
}

.knowledgebase-category-type > a {
  margin-bottom: 10px;
  position: relative;
}

.knowledgebase-category-type > a::before {
  display: inline-block;
  content: '\25B8';
  font-size: 15px;
  margin-right: 4px;
  animation: rotate 0.5s;
  transform: rotate(0deg);
  transition: 0.5s all;
}

.knowledgebase-category-type > a.kb-item-opened::before {
  animation: rotate 0.5s;
  transform: rotate(90deg);
  transition: 0.5s all
}

.kb-item-opened + ul {
  display: block!important;
  transition: display 0.5s;
}

.knowledgebase-menu {
  list-style: none;
  padding: 0;
}

.knowledgebase-menu li {
  padding: 10px 10px 10px 20px;
}

.knowledgebase-menu a {
  text-decoration: none;
  display: block;
  text-align: left;
  font-weight: bolder;
}

.knowledgebase-articles-list,
.knowledgebase-submenu {
  /*display: none;*/
  list-style: none;
}

.kb-main-category {
  border-bottom: 1px solid #dfdede;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}

.partners-distributor-item.dark-bg {
  padding: 40px!important;
}
.mb-5 {
  margin-bottom: 5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.dark-bg {
  background-color: #09182c;
}

.dark-bg h3,
.dark-bg p {
  color: #fff;
}
/*.knowledgebase-menu li:hover .knowledgebase-submenu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
}*/

.knowledgebase-submenu li {
  border: none;
}

.knowledgebase-submenu a {
  padding: 10px 10px 10px 0px;
  display: block;
  text-align: left;
}

.knowledgebase-submenu .knowledgebase-submenu {
  left: 100%;
  top: 0;
  /*display: none;*/
  list-style: none;
}

.kb-artciles-list {
  border: 1px solid #f5f6fc;
  border-radius: 2px;
}

.kb-blog-category,
.kb-blog-listing {
  padding: 30px 40px;
  cursor: pointer;
}

.kb-blog-category h3,
.kb-blog-listing h3 {
  color:#7829f9;
}

.kb-blog-category:hover,
.kb-blog-listing:hover {
  background-color: #f5f6fc;
  -webkit-transition: background 1s; /* For Safari */
    transition: background 1s; /* For modern browsers */
}

.kb-blog-category:not(:last-child),
.kb-blog-listing:not(:last-child) {
  border-bottom: 1px solid #f5f6fc;
}

.kb-item-selected {
  color: #fff;
  background-color: #7829f9;
  padding: 10px;
}

.vl-item {
  top:0;
  position: relative;
  -webkit-transition: top 0.5s ease-out;
  -moz-transition: top 0.5s ease-out;
  -o-transition: top 0.5s ease-out;
  cursor: pointer;
}

.vl-item:hover {
  top: -12px;
}

.vl-item small {
  color: #dfdede;
}

.vl-bg-one-item {
  background-image: url("assets/images/one-background.png")!important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vl-bg-webapp-item {
  background-image: url("assets/images/webapp-background.png")!important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vl-bg-mobile-item {
  background-image: url("assets/images/mobile-background.png")!important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vl-bg-extension-item {
  background-image: url("assets/images/extension-background.png")!important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vl-bg-workshop-item {
  background-image: url("assets/images/workshop-background.png")!important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/*.knowledgebase-menu li:hover .knowledgebase-submenu .knowledgebase-submenu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
}*/


.whitepaper-ul {
  margin: 20px auto;
  padding: 0 40px;
  max-width: 800px;
}

.whitepaper-ul li {
  margin-bottom: 15px;
}


.blog_injector ul {
  padding: 0 40px;
}

.clickable {
  cursor: pointer;
}

.unclickable {
  cursor: default;
}

.content_sec ul, 
.content_sec ol {
  padding: 24px 0 24px 50px;
}

.content_sec ul li, 
.content_sec ol li {
  margin-bottom: 10px;
  font-size: 18px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 25px;
  font-weight: 500;
  color: #556072;
  padding: 0px;
  letter-spacing: 0.44px;
}

.roadmap-navigation-mobile {
  display: none!important;
}

/************ select dropdown ******************/
.dropdown-select {
  background-color: #7829f9;
  width: 250px;
  position: relative;
  height: 70px;
  color: #fff;
  cursor: pointer;
  margin: 50px 0;
  z-index: 3;
  font-size: 18px;
}
.dropdown-select .select-box {
  display: none !important;
}
.dropdown-select span.selection {
  display: block;
  line-height: 70px;
  padding-left: 15px;
  text-align: left;
}
.dropdown-select span.right-icon {
  padding: 20px 15px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #2ecc71;
}
.dropdown-select span.right-icon .fa {
  color: #fff;
  font-size: 33px;
  padding-right: 6px;
  padding-left: 6px;
}
.dropdown-select ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}
.dropdown-select ul.list-items {
  visibility: hidden;
  background-color: #f0f0f3;
  height: 0;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.2s all ease-in-out;
  padding-bottom: 2px;
  margin-bottom: 2px;
  position: relative;
}
.dropdown-select ul.list-items.open {
  height: auto;
  visibility: visible;
}
.dropdown-select ul.list-items li {
  height: 45px;
  line-height: 45px;
  /*background-color: #fff;*/
  padding-left: 15px;
  margin: 0;
  text-align: left;
}
.dropdown-select ul.list-items li.active {
  position: relative;
}
.dropdown-select ul.list-items li.active:before {
  content: " ";
  width: 5px;
  height: 100%;
  position: absolute;
  background: #7829f9;
  left: 0;
}

.arrow-caret {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-caret.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow-caret.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-caret.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow-caret.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/************ END: select dropdown ******************/
.faq-item .question {
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
}

.faq-item .question h3 {
  display: inline-block;
}

.faq-item .question span {
  display: inline-block;
  /*float: right;*/
  margin-right: 10px;
  font-size: 25px;
}

.faq-item .question.active {
  margin-bottom: 0;
}

.faq-item .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.faq-item .answer.active {
  height: auto;
  max-height: 10000px;
  transition: max-height 0.3s ease-in-out;
  
}

.faq-item .answer .inner-answer {
  padding: 20px;
  border: 1px solid #ccc;
}

.learn-more {
  height: 52px;
  width: 200px;
  padding: 15px;
  background-color: #02f3a2;
  color: #09182c;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  border-style: none;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

.learn-more:hover {
  background-color:#fff;
}

.bottom_sec.is-mobile {
  display: none;
  background-color: transparent;
}

.ecosystem-section {
  margin-top: 100px;
  margin-bottom: 100px;
}

.ecosystem-section .img_wrap.img-mobile {
  display: none;
}

.carousel .thumbs-wrapper ul.thumbs {
  padding: 0!important;
}

.slide .learn_wrap .more {
  justify-content: center!important;
}

.learn_wrap .more p {
  margin-bottom: 0!important;
}

.input-error-message span {
  color: red;
  font-size: 12px;
}

@media only screen and (max-width:900px){
  .bottom_sec.is-desktop {
    display: none;
  }

  .bottom_sec.is-mobile {
    display: block;
  }
  
  

  .ecosystem-section img {
    width: 70%!important;
  }

  .ecosystem-section .img_wrap {
    text-align: center;
  }
  
  .ecosystem-section .img_wrap.img-desktop {
    display: none;
  }
  
  .ecosystem-section .img_wrap.img-mobile {
    display: block;
  }
  
}

@media only screen and (max-width: 1023px) {
  .content_sec {
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .content_sec {
    padding: 0px 15px;
  }

  .roadmap-navigation {
    display: none;
  }

  .roadmap-navigation-mobile {
    display: block!important;
  }
}

@media only screen and (max-width: 840px) {

  .partners-distributor-item {
    width: 90%;
  }

}

@media only screen and (max-width:900px) {
  .bi-block-image-text .video_wrap img {
    display: none;
  }
  .cs-premium-features {
    max-width: 60%;
  }

  .bi-block-image-text .video_wrap_mobile img {
    display: block;
  }
}

@media only screen and (max-width:910px) {

  .knowledgebase-toggle{
    position:relative;
    height:41px;
    width:41px;
    border:1px solid #f5f6fc;
    background-color:#f5f6fc;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap:5px;
    transition:all 0.3s ease-in-out;
    margin-left: 40px;
    margin-top: 20px;
  }
  .knowledgebase-toggle .l{
    height:2px;
    width:20px;
    background-color:#000;
    transition:all 0.3s ease-in-out;
  }
  .knowledgebase-toggle.close .cl{
    display: none;
  }
  
  .knowledgebase-toggle.close .fl{
    transform: rotate(-135deg);
  }
  .knowledgebase-toggle.close .sl{
    transform: rotate(135deg);
  }
  .knowledgebase-toggle.close .l{
    position:absolute;
  }

  .knowledgebase-content {
    grid-column: span 12;
    min-height: calc(100vh - 140px);
  }

  .knowledgebase-index {
    transition:all 0.3s ease-in-out;
    grid-column: span 12;
    position: absolute;
    width: 0px;
    padding: 0;
  }

  .knowledgebase-index.open {
    transition:all 0.3s ease-in-out;
    width: auto;
    padding: 20px;
    z-index: 2;
    margin-left: 20px;
    max-width: calc(100vw - 80px);
  }



  .knowledgebase-index.open .knowledgebase-sidebar {
    height: calc(100vh - 215px);
  }
}
