.use_cases {
  margin: 100px 0px;
}

.use_cases .content_sec h2 {
    margin:50px 0px;
}   

.use_cases .content_sec .card_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 35px;
}

.use_cases .content_sec .card_grid .card {
  background-color: #f5f6fc;
  min-height: 500px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  border:5px solid #f5f6fc;
  transition:all 0.4s ease-in-out;
}

.use_cases .content_sec .card_grid .card:hover{
  border:5px solid #02f3a2;
}

.use_cases .content_sec .card_grid .card.unclickable:hover{
  border:5px solid #bebebf;
}

.use_cases .content_sec .card_grid .card img {
  width: 100%;
}
.use_cases .content_sec .card_grid .card p.tag {
  font-size: 28px;
  font-weight: 600;
  color: #09182c;
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 0;
}

.use_cases .content_sec .card_grid .card .overlay {
    position: absolute;
    top:100%;
    left:0px;
    right:0px;
    height:500px;
    width:100%;
    background-color:#09182c;
    transition:all 0.2s ease-in-out;
    padding:30px;
    z-index: 1; 
}

.use_cases .content_sec .card_grid .card:hover .overlay{
top:0px!important;

}

.use_cases .content_sec .card_grid .card .overlay p.title{
        color:#fff;
        font-size:28px;
        line-height: 32px;
        font-weight: 600;
}
.use_cases .content_sec .card_grid .card .overlay p.para{
    color:#fff;
    font-size:18px;
    line-height:25px;
    font-weight:400;
    margin-top:20px;
    white-space: pre-line;
}
.use_cases .content_sec .card_grid .card .overlay .more{
    position:absolute;
    bottom:20px;
    left:20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:100%;
}

.use_cases .card_grid .card {
  cursor: pointer;
}


.use_cases .card_grid .card.unclickable {
  cursor: not-allowed;
}

.use_cases .card_grid .card.unclickable .grey-out::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #09182c;
  z-index: 3;
  opacity: 0.3;
}

@media only screen and (max-width:600px){
  .use_cases .content_sec h2{
    text-align: center;
  }
}