.multiple{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#09182c;
    padding:100px;
    margin-bottom:100px;
}

.multiple .content_sec{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.multiple h2{
    color:#fff;
    margin:20px 0px;
    text-align: center;
}

.multiple p{
    color:#fff;
    text-align: center;
    white-space: pre-wrap;
}

.multiple .img_wrap{
    width:100%;
    display: grid;
    place-content: center;
    margin-top:50px;
}
.multiple .img_wrap img{
    width:100%;
    width:1000px;    
}

#silentbreach-badge {
    text-align: center;
}

#silentbreach-badge img{
    width: 85%;
}

@media only screen and (max-width:1400px){
    .multiple .img_wrap img{
        width:100%;
    }
}

@media only screen and (max-width:900px){
    .multiple{
        padding:80px 0px;
    }
}

@media only screen and (max-width:600px){
    .multiple{
        margin-bottom:60px;
    }
}

@media only screen and (max-width:500px){
    .multiple{
        padding:80px 0px 40px;
    }
}