.subscription {
  padding-bottom: 60px;
}

.subscription h2 {
  text-align: center;
  margin-bottom: 50px;
}

.subscription p {
  margin: 20px 0px 30px;
}

.subscription a{
    color:#7829f9;
}