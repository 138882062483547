
.mission_vision {
    margin:100px 0px;
}
.mission_vision .bigrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:100px;
}

.mission_vision .bigrid .text_wrap{
display: flex;
flex-direction: column;
justify-content: center;
}

.mission_vision .bigrid .text_wrap h2{
    margin-bottom:20px;
}

.mission_vision .bigrid .img_wrap img{
    width:100%;
}

@media only screen and (max-width:900px){
    .mission_vision .bigrid{
       display: flex;
       flex-direction: column-reverse;
       gap:50px;
    }
    .mission_vision .bigrid .text_wrap h2{
        text-align: center;
    }
    .mission_vision .bigrid .text_wrap p{
        text-align: center;
    }

    .mission_vision .bigrid .btn_wrap{
        text-align: center;
    }
}

@media only screen and (max-width:600px){
    .mission_vision {
        margin:60px 0px;
    }
}