.partners{
        background-color:#09182c;
        padding:100px 0px;        
}

.bi_grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:80px;
}

.bi_grid .text{
}
.bi_grid .text h1{
    color:#fff;
}
.bi_grid .text p.para{
    color:#fff;
    margin-top:20px;
    font-size:22px;
    font-weight:500;
    line-height: 31px;
}

.bi_grid .img img{
    width:100%;
}

.partners button.second {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff!important;
    margin-top: 50px;
  }
  
  .partners button.second  {
    padding: 16px 32px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    text-transform: capitalize;
    border-style: none;
    transition: all 0.3s ease-in-out;
    width: 250px;
  }

@media only screen and (max-width:800px){
    .bi_grid{
        grid-template-columns: 1fr;
        gap:100px;
    }
    .bi_grid .text{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bi_grid .text h1{
        text-align:center;
    }
    .bi_grid .text p.para{
        text-align:center;
        font-size:18px;
        line-height:28px;
    }
}

@media only screen and (max-width:600px){
    .bi_grid{
        gap:60px;
    }
    .partners{
        padding:60px 0px;        
}
}