.terms{
padding-bottom:60px;
}

.terms h2{
text-align: center;
margin-bottom:50px;
}

.terms p{
    margin:20px 0px 30px;
}