.about_landing .text_wraper {
  margin-top: 100px;
  
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_landing .text_wraper h1 {
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.about_landing .text_wraper p.para {
  font-size: 22px;
  line-height: 31px;
  font-weight: 500;
  text-align: center;
  white-space: pre-wrap;
}

.about_landing .tri_grid {
  background-color: #09192d;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 30px 20px;
}

.about_landing .tri_grid .part img {
  margin-left: auto;
  margin-right: auto;
}

.about_landing .tri_grid_no_margin {
  margin-top: 0px;
}

.about_landing .tri_grid_no_margin svg {
  width: 66px;
}

.about_landing .tri_grid .part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 50px;
  justify-content: center;
  text-align: center;
}

.about_landing .tri_grid .part.mid {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

.about_landing .tri_grid .part h3 {
  color: #fff;
  margin: 20px 0px;
  text-align: center;
  width: 100%;
}

.about_landing .tri_grid .part h2 {
  color: #fff;
  font-size: 32px;
  text-align: center;
  width: 100%;
}

.about_landing .tri_grid .part p {
  color: #fff;
  text-align: center;
  width: 100%;
}

.about_landing .tri_grid .part img{
  width: 50%;
}

.about_landing .tri_grid_no_margin .item-eco h2,
.about_landing .tri_grid_no_margin .item-eco h3 {
  color: #45e097;
}

.about_landing .tri_grid_no_vertical_padding {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.about_landing .tri_grid_no_vertical_padding hr {
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
}

.row-grids {
  display: grid;
  grid-template-rows: 1fr auto 1fr; /*grid-template-rows: 0.95fr 0.1fr 0.95fr; Equal height rows */
  height: auto; /* Adjust as needed */
  gap: 0; /* Gap between rows */
  margin-top: 100px;
}

@media only screen and (max-width: 1100px) {
  .about_landing .tri_grid {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .row-grids {
    display: block;
  }
  
  .about_landing .tri_grid_no_vertical_padding {
    display: none!important;
  }
  .about_landing .text_wraper h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .about_landing .text_wraper p.para {
    font-size: 18px;
    line-height: 26px;
  }
  .about_landing .tri_grid{
    display: flex;
    flex-direction: column;
    gap:50px;
    padding:50px;
  }
  .about_landing .tri_grid .part{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .about_landing .tri_grid .part.mid{
      border-right: none;
      border-left: none;
      border-top:1px solid #fff;
      border-bottom:1px solid #fff;
      padding:50px 0px;
  }
  .about_landing .tri_grid .part.item-eco{
      border-top:1px solid #fff;
      padding:50px 0 0 0;
  }
  .about_landing .tri_grid .part h3{
      text-align: center;
      margin:15px 0px;
  }
  .about_landing .tri_grid .part p{
      text-align: center;
  }
}
@media only screen and (max-width: 767px){
    .about_landing .tri_grid{
        padding:50px 20px;
    }
}
@media only screen and (max-width: 500px) {
  .about_landing .text_wraper h1 {
    font-size: 38px;
    line-height: 43px;
  }

  .about_landing .text_wraper p.para {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width:600px){
  .about_landing .text_wraper {
    margin-top: 60px;
  }
  .about_landing .tri_grid{
    margin-top:60px;
  }

  .about_landing .tri_grid_no_margin {
    margin-top: 0;
  }
}