.popup_video {
  min-height: 100vh;
  width: 100%;
  background-color: #09182c;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
}

.popup_video button {
  height: 48px;
  width: 48px;
  border-style: none;
  background-color: transparent;
  position: absolute;
  /*top: 40px;
  left: 40px;*/
  position: fixed;
}

.popup_video .video_wrap {
  position: absolute;
  left: 0; right: 0;
  top: 50%;
  padding-bottom: 56.2%;
  margin-top: -28.1%;
  height: 0;
  /*overflow: hidden;*/
  max-width: 1150px;
  max-height: 646px;
  background-image: url("../../../../assets/images/video-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.popup_video .video_wrap .wistia_video {
  border-style: none;
  position:absolute; 
  top:0; 
  left: 0;
}

@media only screen and (min-width: 1150px) {
  .popup_video .video_wrap {
    top: 0;
    margin-top: calc(50vh - 323px);
    margin-left: calc(50vw - 575px);
    padding-bottom: 0;
    height: 646px;
  }
}