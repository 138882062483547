.map {
  margin: 100px 0px;
}
.map .map_wrap {
  position: relative;
  width: 100%;
  padding:70px 50px;
 overflow: auto;
}
.map .map_wrap .location {
  display: grid;
  place-items: center;
  background-color: #02f3a2;
  /* border: 2px solid #7829f9; */
  width: 30px;
  height: 30px;
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  transition: all 0.2s ease-in-out;
  position: absolute;
  cursor: pointer;
}
.map .map_wrap .location span {
  font-size: 11px;
  font-weight: 700;
  color: #09182c;
  text-align: center;
  display: block;
  transform: rotate(-45deg);
}
.map .map_container{
    width:100%;
    overflow-x: auto;
}
.map .map_container .map_wrap{
    min-width:700px;
}
.map .map_wrap .location:after{
  position:absolute;
  content:'';
  top:-50px;
  left:-50%;
  transform: rotate(-45deg) translateX(-50%);
  background-color:#7829f9;
  color:#fff;
font-size:14px;
  padding:8px;
  border-radius: 5px;
  opacity:0;
  transition:all 0.3s ease-in-out;
}

.map .map_wrap .location:hover:after{
  opacity:1;
}
.map .map_wrap .location:hover {
  width: 33px;
  height: 33px;
}

.map .map_wrap .location.a:after{
  content:"United States";
  top:-70px;
  width:90px;
}
.map .map_wrap .location.b:after{
  content:"Singapore";
  top:-60px;
}
.map .map_wrap .location.c:after{
  content:"Australia";  
  top:-56px;
}
.map .map_wrap .location.d:after{
  content:"France";  
}
.map .map_wrap .location.e:after{
  content:"United Arab Emirates";
  top:-100px;
  width:140px;
}

.map .map_wrap .location.a {
  top: 35%;
  left: 21%;

}

.map .map_wrap .location.b {
  top: 53%;
    left: 70.5%;
}

.map .map_wrap .location.c {
  top: 67%;
  left: 81%;
}

.map .map_wrap .location.d {
  top: 34%;
  left: 48%;
}

.map .map_wrap .location.e {
  top: 45%;
  left: 59%;

}

.center {
  text-align: center;
}

.map_mobile {
  display: none;
  text-align: center;
  margin: 60px auto;
}

.map_mobile img {
  max-width: 100%;
}

@media only screen and (max-width:800px){
    .map .map_wrap .location{
        height:25px;
        width:25px;
    }

    .map .map_wrap .location:hover {
      width: 28px;
      height: 28px;
    }

    .map_wrap {
      display: none;
    }

    .map_text_wrap {
      display: grid;    
      grid-template-columns: repeat( auto-fit, calc(50% - 15px) );
      gap: 30px;
    }

    .map_text_wrap .location-flag {
      /*width: 50%;*/
      margin: 60px 0 20px 0;
      text-align: center;
      position: relative;
      box-sizing: border-box;
    }

    .map_text_wrap .location-flag img {
      width: 70%;
      margin-bottom: 10px;
    }

    .map_text_wrap .location-flag .country-name {
      font-weight: 600;
      font-size: 1rem;
      text-align: center;
    }

    .map_mobile {
      display: block;
    }
}
@media only screen and (min-width:801px){

  .map_text_wrap {
    display: none;
  }

}
@media only screen and (max-width:600px){
  .map {
    margin: 60px 0px;
  }
}